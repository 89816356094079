/*Inbuilt App.js styles*/
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,700;0,900;1,400;1,700;1,900&family=Orbitron:wght@400;500;600;700;800;900&family=Titan+One&display=swap");

#wewe {
  text-align: left;
  margin: 2% 10% 0% 10%;
}

.singleblog {
  background-color: rgb(225, 219, 219);
  padding: 2%;
  margin-bottom: 1%;
  cursor: pointer;
}

.singleblog:hover {
  cursor: pointer;
  transform: scale(1.01);
}

.control {
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev {
  background-color: #25ff03;
  width: 60px;
  border: none;
  border-radius: none;
  cursor: pointer;
  height: 20px;
}

.breadcrumsul {
  display: flex;
  list-style: none;
}

.page {
  padding: 5px;
}

.sliderdiv1 {
  margin-top: 2%;
  height: 89vh;
  background-color: whitesmoke;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.sliderimg img {
  border-radius: 2px;
  background-color: whitesmoke;
}

.sliderdiv {
  height: 88vh;
  display: flex !important;
  align-items: center;
}

.div1 {
  background-color: #002147;
  color: white;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.div6 {
  background-color: #ffd300;
  color: black;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.div7 {
  background-color: #004953;
  color: black;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.div1 h1,
.div6 h1,
.div7 h1 {
  font-family: "Merriweather", serif;
  font-family: "Orbitron", sans-serif;
  font-family: "Titan One", cursive;
  font-size: 48px;
}

.div1 button,
.div6 button,
.div7 button {
  border: 1px solid white;
  background-color: greenyellow;
  border-radius: 0px;
  color: black;
  padding: 14px;
  cursor: pointer;
}

.div1 p,
.div6 p,
.div7 p {
  width: 50%;
  font-family: monospace;
  font-size: 16px;
  font-weight: 200;
  text-align: left;
}

.div2 {
  background-image: url("./Images/About_us2.png");
  height: 100%;
  width: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.div3 {
  background-image: url("./Images/About_us1.png");
  height: 100%;
  width: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.div4 {
  background-image: url("./Images/About_us3.png");
  height: 100%;
  width: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.div5 {
  background-image: url("./Images/carouselbg.jpg");
  height: 100%;
  width: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.siderholder {
  height: 84vh;
  margin: 1% 2% 1% 2%;
}

.delete {
  color: red;
  cursor: pointer;
  padding: 2px;
  border: 1px solid black;
  border-radius: 2px;
}

.edit {
  color: blue;
  cursor: pointer;
  padding: 2px;
  border: 1px solid black;
}

.edits {
  margin-top: 8px;
  gap: 12px;
  display: flex;
}

.slidercont {
  margin-left: 2%;
  margin-right: 2%;
  background-color: grey;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App .editor {
  width: 900px;
  margin-left: 20%;
  margin-top: 50px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sc-fzoLsD {
  display: none;
}

.sc-fzpans {
  display: none;
}
/*SiteWide/optional styles*/
a {
  text-decoration: none;
  color: #000000;
}
.Deco_link /*lets us add the decoration back to a link if we want to*/ {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}
/*Under construction styles*/
#Construction_container img {
  max-width: 100vw;
}

/*Header styles*/
.header {
  height: 30px;
  display: flex;
  padding: 20px 80px;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 4px 10px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 4px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 4px 10px 0px rgba(0, 0, 0, 0.75);
}

.logo {
  width: 90px;
}

#Header_link_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  background-color: white;
}

/* slider */

/* blog page */
.spa {
  gap: 6px;
}

.addbtn {
  border: 1px solid white;
  padding: 4%;
  border-radius: none; /* Adjust as needed */
  background-color: yellowgreen;
  color: white;
  width: 100px;
  cursor: pointer;
}

.btn:hover {
  background-color: rgb(2, 2, 193);
  cursor: pointer;
}

.account {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4%;
  width: 25%;
  background-color: black;
  color: white;
  height: 100%;
  padding: 3%;
  border-radius: 6px;
}

.content-blog {
  text-align: start;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content-blog h2 {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 900;
}

.content-blog p {
  font-size: 13px;
  font-weight: 600;
  font-family: monospace;
}

.noblogs {
  background-color: #000000;
  height: 78vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: url("./Images/blog-construction.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blogbody {
  margin: 0% 10% 2% 10%;
  background-color: whitesmoke;
  padding: 7%;
  text-align: left;
}

.blogbody h2 {
  text-transform: capitalize;
  text-align: center;
  font-size: 32px;
  font-weight: 900;
}

.titlei-input {
  width: 100%;
  border: none;
  border-radius: none;
}

.blogbody p {
  font-size: 20px;
  font-weight: 400;
}

.writter {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.blogs_cont {
  display: flex;
  margin: 1% 10% 0% 10%;
  width: 80%;
  gap: 25px;
}

.blogs {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.blog-cont {
  text-align: flex-start;
}

.profile-image {
  display: flex;
  gap: 14px;
}
.profile-image img {
  width: 40px;
  border-radius: 50%;
  height: 40px;
  background-color: #1f5156;
}

.profile-image p {
  font-family: monospace;
  font-size: 12px;
  text-transform: capitalize;
}

.blog-cont {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.image-div {
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-div img {
  width: 150px;
  height: 150px;
}

/* carousel for partners */
.holder {
  height: 97vh;
  margin: 2% 10%;
  color: #000000;
}

.holder-component {
  height: 50vh;
  text-align: center;
  margin: 2% 10%;
}

.Content {
  text-align: center;
  min-height: 80vh;
}

.slick-prev,
.slick-next {
  background-color: #000000;
}

.line {
  width: 100%;
  background-color: green;
  height: 1px;
}

.carousel-cont {
  background-color: black;
  width: 300px;
  padding: 2%;
  border: 1px solid white;
  height: 300px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-immage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-immage img {
  width: 150px;
}

#Mobile_nav a {
  /*This is a modified version of the default H2 tag*/
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 5rem;
}
#Header_menu_btn {
  display: none;
}
#header_menu_btn img {
  display: none;
}
/*Gradually reducing the size the header links take up to keep everything in line as the page shrinks*/
@media screen and (max-width: 1250px) and (min-width: 1000px) {
  #Header_link_container a {
    margin-right: 2.5rem;
  }
}
@media screen and (max-width: 999px) and (min-width: 801px) {
  #Header_link_container a {
    margin-right: 1rem;
  }
}
@media screen and (max-width: 800px) and (min-width: 601px) {
  #Header_link_container a {
    margin-right: 0.25rem;
    font-size: 1.2rem;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 600px) {
  #Header_menu_btn {
    display: inline-block;
    margin: auto;
  }
  header {
    display: flex;
    width: 100vw;
    margin-bottom: 1rem;
  }
  #Header_link_container {
    display: none;
  }
}
#Mobile_nav {
  width: 100vw;
}
#Mobile_nav a {
  width: 60vw;
  margin-left: 20vw;
  display: block;
  margin-top: 5vh;
}


/*Homepage styles*/

#Carousel {
  overflow: hidden;
  width: 80%;
  margin-left: 10%;
  margin-top: 1%;
}
#Carousel figure {
  position: relative;
  width: 500%;
  margin: 0;
  left: 0;
  animation: 20s Carousel infinite;
}
#Carousel figure img {
  float: left;
  width: 20%;
}
@keyframes Carousel {
  0% {
    left: 0;
  }
  20% {
    left: 0;
  }
  25% {
    left: -100%;
  }
  45% {
    left: -100%;
  }
  50% {
    left: -200%;
  }
  70% {
    left: -200%;
  }
  75% {
    left: -300%;
  }
  95% {
    left: -300%;
  }
  100% {
    left: -400%;
  }
}

/*About us styles*/

.aboutlink {
  color: greenyellow;
}

.row {
  display: flex;
  margin: 2% 10%;
}
.column {
  width: 40rem;
  padding: 1rem 5rem 1rem 2.5rem;
  min-height: 300px;
}
.column img {
  max-width: 100%;
}
.column h1 {
  text-align: left;
}
.column p {
  text-align: left;
}

/*Products styles*/
.cards {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10%;
  margin: 6% 10%;
}

.card {
  width: 350px;
  background-color: rgb(240, 240, 240);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 500px;
  border-radius: 9px;
  cursor: pointer;
  transition: transform 0.2s;
  color: black;
}

.card-title {
  font-family: "Times New Roman", Times, serif;
  font-weight: 600;
  font-size: 24px;
}

.pdesc {
  display: flex;
  text-align: flex-start;
  align-items: center;
  justify-content: center;
  padding: 4%;
}

.desc {
  font-size: 15px;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
}

.card:hover {
  transform: scale(1.02);
}

.card-image {
  max-width: 300px;
}

.img {
  width: 300px;
  border-radius: 4px;
}

.card-content {
  width: 100%;
  background-color: greenyellow;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .card {
    flex-basis: 100%;
    max-width: 100%;
  }
  .card-image .img {
    width: 98%;
    border-radius: 4px;
  }
}

.card-title {
  font-family: "Times New Roman", Times, serif;
  font-weight: 600;
  font-size: 24px;
}

.pdesc {
  display: flex;
  text-align: flex-start;
  align-items: center;
  justify-content: center;
  padding: 4%;
}

.desc {
  font-size: 15px;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
}

.card:hover {
  transform: scale(1.02);
}

.card-image {
  max-width: 300px;
}

.img {
  width: 300px;
  border-radius: 4px;
}

.card-content {
  width: 100%;
  background-color: greenyellow;
  text-align: center;
}

/*Product pages styles*/
.Product_page img {
  width: 30vw;
  max-height: 30vh;
}
.Centered_text {
  margin-left: 20%;
  width: 60%;
}
.Product_Hero_item {
  max-width: 90%;
  padding: 0 1vw 5vw 1vw;
}

.Product_page .column {
  float: left;
  width: 40rem;
  padding: 0rem 2.5rem 2.5rem 2.5rem;
  max-height: 300px;
}
video {
  margin-top: 5vh;
}
@media screen and (max-width: 600px) {
  .Centered_text,
  .Product_page p {
    margin-left: 10vw;
    width: 80vw;
  }
  .Product_page img {
    width: 70vw;
    max-height: 30vh;
  }
}

/*Services styles*/
#Services_container {
  margin-left: 10%;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.Service_item {
  height: 25%;
  width: 20%;
  padding: 5%;
}
.Service_item img {
  width: 75%;
  padding: 12.5%;
}
.Service_img_background {
  margin: auto;
  background-color: greenyellow;
  border-radius: 50%;
  height: 10vw;
  width: 10vw;
  overflow: hidden;
}
@media screen and (max-width: 600px) {
  .Service_item {
    height: 18rem;
    width: 19rem;
    padding-bottom: 0rem;
  }
  .Service_img_background {
    height: 30vw;
    width: 30vw;
  }
  .Service_img_background h2 {
    width: 90%;
  }
  #servicetitle {
    width: 90%;
    word-wrap: break-word;
  }
}

/*Contact us styles*/
#Contacts {
  height: 90vh;
  background-color: greenyellow;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#Contacts_comp {
  height: 50vh;
  background-color: greenyellow;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.linkedin {
  color: black;
}

#Contacts ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-flex-start;
}

#Contacts_comp ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-flex-start;
}
#Contacts_comp ul li {
  display: flex;
  justify-content: flex-flex-start;
  margin-left: 2.5vw;
  align-items: center;
}

#Contacts ul li {
  display: flex;
  justify-content: flex-flex-start;
  margin-left: 2.5vw;
  align-items: center;
}

#Contacts ul img {
  float: left;
  padding-right: 2rem;
  max-width: 2rem;
}

#Contacts_comp ul img {
  float: left;
  padding-right: 2rem;
  max-width: 2rem;
}
@media screen and (max-width: 700px) {
  #Contacts {
    margin-left: 5vw;
    width: 90vw;
    align-items: center;
  }
  #Contacts ul {
    margin-left: 5vw;
  }
  #Contacts ul li {
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }
  #Contacts ul img {
    padding: 0;
    float: none;
  }
}
/*Blog styles*/
.Blog_preview {
  border-style: solid;
  border-color: #525151;
  border-radius: 10%;
  width: 60vw;
  margin-left: 20vw;
  margin-bottom: 2.5vh;
}
.Blog_preview:hover {
  background-color: #d9d9d9;
  transition: 1s;
  box-shadow: 10px 10px 10px #ffef03;
}
.Blog_preview h2,
.blog_preview p {
  display: inline-block;
}

@media screen and (max-width: 700px) {
  .blog_preview {
    margin-left: 10vw;
    width: 80vw;
  }
}
/*Parteners styles*/
#Partner_container {
  width: 95vw;
  margin-left: 2.5vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10vh;
}
.partner {
  max-width: 20vw;
  margin: 2.5vh 1vw 2.5vh 1vw;
  transition: 1s;
}
#Partner_container img {
  max-width: 18vw;
  margin-left: 1vw;
  display: inline-block;
  z-index: 0;
}
.partner_info {
  background-color: #6e6d6d;
  opacity: 85%;
  display: none;
  color: white;
  padding-bottom: 0.5vh;
  border-radius: 25%;
  max-width: 15vw;
  height: 75%;
}
.partner:hover {
  transform: scale(1.1);
}
.partner_img:hover {
  opacity: 25%;
}
.partner_info:hover,
.partner_img:hover ~ .partner_info {
  display: inline-block;
  z-index: 10;
  transform: translate(0%, -75%);
}

@media screen and (max-width: 700px) {
  .partner {
    min-width: 40vw;
  }
  .partner_info:hover,
  .partner_img:hover ~ .partner_info {
    display: none;
  }
}

.navbar {
  background-color: white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 20;
  color: black;
  box-shadow: -1px 3px 11px 2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 3px 11px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 3px 11px 2px rgba(0, 0, 0, 0.75);
  margin-bottom: 4px;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.nav-logo {
  color: #f5b921;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.nav-logo img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  color: #002147;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #ffdd40;
}

.nav-item.active {
  color: #ffdd40;
  border: 1px solid #ffdd40;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: yellowgreen;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    width: 60%;
  }
  .nav-item .active {
    color: #ffdd40;
    border: none;
  }
  .nav-links {
    padding: 1rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #ffdd40;
  }
}

.products-div {
  background-color: #ffd300;
  color: black;
  padding: 1% 0% 1% 0%;
  margin: 0% 2% 0% 2%;
}
.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#image {
  width: 500px;
  padding: 1%;
  border: 1px solid black;
}

.inputdiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

#titlei-input {
  width: 50%;
  text-transform: uppercase;
  padding: 6px;
}

.blogbtn {
  padding: 10px;
  width: 60px;
  height: 40px;
  background-color: #25ff03;
  margin: 1%;
  border: none;
}

.blogbtn:hover {
  background-color: green;
  cursor: pointer;
}

.displaynone {
  display: none;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

/* general styling */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
}

.search {
  width: 80%;
  padding: 1%;
  margin: 1%;
  border-radius: 5px;
  border: 1px solid burlywood;
}


.slick-arrow {
  background-color: !important red;
}

/* CSS for tablet screens */
@media (max-width: 768px) {
  .products-div {
    padding: 20px;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}




@media (max-width: 480px) {
  .products-div {
    padding: 10px;
  }

  .cards {
    display: flex;
    flex-direction: column;
  }
  .siderholder {
    height: 65vh;

  }
  .sliderdiv {
    height: 62vh; /* Adjust the height as needed */
    width: 100vh%; /* Adjust the width as needed */
    background-color: red;
    position: relative;
  }
  .div1,.div7,.div6 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    height: 70vh;
    color: white;
  }
  .div2,.div3,.div4,.div5 {
    position: relative;
    z-index: 1;
    width: 100%;
  }

  .div1 h1, .div6 h1, .div7 h1 {
    font-family: "Titan One", cursive;
    font-size: 18px;
    width: 90%;
  }
  .div1 p, .div6 p, .div7 p {
    width: 90%;
    font-family: monospace;
    font-size: 14px;
    font-weight: 200;
    text-align: left;
  }
  .div1 button, .div6 button, .div7 button {
    border: 1px solid white;
    background-color: greenyellow;
    border-radius: 0px;
    color: black;
    padding: 14px;
    cursor: pointer;
    margin-bottom: 1%;
  }
  .aboutdiv {
    width: 100%;
    margin-top: 5%;
  }
  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .column {
    width: 90%;
  }

  #aboutimage {
    height: 100%;
    width: 100%%;
  }
  .column img {
    max-width: 100%;
  }

  .products-div {
    background-color: #ffd300;
    color: black;
    padding: 1% 0% 1% 0%;
    margin: 0%;
  }
  .contact_ul li p {
    text-align: start;
  }
  .blogs_cont {
    display: flex;
    margin: 1% 10% 0% 10%;
    width: 80%;
    gap: 25px;
    flex-direction: column-reverse;
  }
  .account {
    width: 100%;
  }
}



.aboutdiv {
  margin-top: 10%;

}

.footer {
  background-color: #004658;
  color: #fff;
}
.footer-wave-svg {
  background-color: transparent;
  display: block;
  height: 30px;
  position: relative;
  top: -1px;
  width: 100%;
}
.footer-wave-path {
  fill: #fffff2;
}

.footer-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  padding: 40px 15px 450px;
  position: relative;
}

.footer-content-column {
  box-sizing: border-box;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  color: #fff;
}

.footer-content-column ul li a {
color: #fff;
text-decoration: none;
}

.footer-logo-link {
  display: inline-block;
}
.footer-menu {
  margin-top: 30px;
}

.footer-menu-name {
  color: #fffff2;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: .1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}
.footer-menu-list {
  list-style: none;
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 0;
}
.footer-menu-list li {
  margin-top: 5px;
}

.footer-call-to-action-description {
  color: #fffff2;
  margin-top: 10px;
  margin-bottom: 20px;
}
.footer-call-to-action-button:hover {
  background-color: #fffff2;
  color: #00bef0;
}
.button:last-of-type {
  margin-right: 0;
}
.footer-call-to-action-button {
  background-color: #027b9a;
  border-radius: 21px;
  color: #fffff2;
  display: inline-block;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: .1em;
  line-height: 18px;
  padding: 12px 30px;
  margin: 0 10px 10px 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .2s;
  cursor: pointer;
  position: relative;
}
.footer-call-to-action {
  margin-top: 30px;
}
.footer-call-to-action-title {
  color: #fffff2;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: .1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}
.footer-call-to-action-link-wrapper {
  margin-bottom: 0;
  margin-top: 10px;
  color: #fff;
  text-decoration: none;
}
.footer-call-to-action-link-wrapper a {
  color: #fff;
  text-decoration: none;
}





.footer-social-links {
  bottom: 0;
  height: 54px;
  position: absolute;
  right: 0;
  width: 236px;
}

.footer-social-amoeba-svg {
  height: 54px;
  left: 0;
  display: block;
  position: absolute;
  top: 0;
  width: 236px;
}

.footer-social-amoeba-path {
  fill: #027b9a;
}

.footer-social-link.linkedin {
  height: 26px;
  left: 3px;
  top: 11px;
  width: 26px;
}

.footer-social-link {
  display: block;
  padding: 10px;
  position: absolute;
}

.hidden-link-text {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px,1px,1px,1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  top: 50%;
}

.footer-social-icon-svg {
  display: block;
}

.footer-social-icon-path {
  fill: #fffff2;
  transition: fill .2s;
}

.footer-social-link.twitter {
  height: 28px;
  left: 62px;
  top: 3px;
  width: 28px;
}

.footer-social-link.youtube {
  height: 24px;
  left: 123px;
  top: 12px;
  width: 24px;
}

.footer-social-link.github {
  height: 34px;
  left: 172px;
  top: 7px;
  width: 34px;
}

.footer-copyright {
  background-color: #027b9a;
  color: #fff;
  padding: 15px 30px;
text-align: center;
}

.footer-copyright-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.footer-copyright-text {
color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

.footer-copyright-link {
  color: #fff;
  text-decoration: none;
}







/* Media Query For different screens */
@media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
.footer-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  padding: 40px 15px 1050px;
  position: relative;
}
}
@media (min-width:480px) and (max-width:599px)  { /* smartphones, Android phones, landscape iPhone */
.footer-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  padding: 40px 15px 1050px;
  position: relative;
}
}
@media (min-width:600px) and (max-width: 800px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
.footer-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  padding: 40px 15px 1050px;
  position: relative;
}
}
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */

}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */

}
@media (min-width:1281px) { /* hi-res laptops and desktops */

}




@media (min-width: 760px) {
.footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 450px;
    position: relative;
}

.footer-wave-svg {
    height: 50px;
}

.footer-content-column {
    width: 24.99%;
}
}
@media (min-width: 568px) {
/* .footer-content-column {
    width: 49.99%;
} */
}
