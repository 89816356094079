.logindiv {
  font-family: "Averia Sans Libre", sans-serif;
  overflow: hidden;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: #ece9e6;
  background: -webkit-linear-gradient(to right, #ffffff, #ece9e6);
  background: linear-gradient(to right, #ffffff, #ece9e6);
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  color: #eeeeee;
  background: #222831;
  max-width: 550px;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.75);
}

.wrapper h2 {
  margin: 35px 0;
  font-weight: bold;
  font-size: 20px;
}

.wrapper .footer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  height: 100px;
  background-color: #393e46;
  clip-path: polygon(63% 68%, 100% 25%, 100% 100%, 0 100%, 0 0);
}

.group {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 35px;
  padding: 0 35px;
}

.group .label {
  position: absolute;
  transition: all 0.5s ease;
  pointer-events: none;
}

.group .input {
  width: 350px;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px white;
  outline: none;
  color: white;
}

.group .input:focus ~ .label,
.group .input:valid ~ .label {
  margin-top: -25px;
  transition: all 0.5s ease;
}

.btn {
  color: #eeeeee;
  background-color: transparent;
  outline: none;
  padding: 3px;
  padding-bottom: 0;
  margin: 35px 0;
  border: none;
  border-bottom: solid 1px white;
  transition: all 1s linear;
}

.btn:hover {
  color: #bbe1fa;
  border-bottom: solid 1px #bbe1fa;
  transition: all 1s linear;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
